import { api } from '@redux/api/api'
import type { CurrenciesList, LanguagesList } from '@reduxCommonApp/types/commonAppState'
import type { ISiteInfo } from '@reduxCommonApp/types/siteInfo'

import type { ConfigurationType } from '@Types/common/configurationBlocks'
import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import type { ConfigurationTypes } from '@constants/configuration/configuration'
import userSettingsStorage from '@storageServices/storageEntities/userSettings'

const commonAppApi = api.injectEndpoints({
  endpoints: build => ({
    getConfiguration: build.query<ConfigurationType, ConfigurationTypes>({
      keepUnusedDataFor: 600,
      query: (type: ConfigurationTypes) => ({ url: urlVersionPrefix + `configuration/${type}` }),
    }),
    getCurrencies: build.query<CurrenciesList, boolean | void>({
      query: visible => ({
        params: { visible },
        url: urlVersionPrefixV2 + `currencies`,
      }),
    }),
    getFile: build.query<FileType, number>({
      query: fileId => ({ url: urlVersionPrefix + `entity/file/${fileId}` }),
    }),
    getLanguages: build.query<LanguagesList, void>({
      query: () => ({ url: urlVersionPrefix + 'languages' }),
    }),
    getSiteInfo: build.query<ISiteInfo, boolean | void>({
      // boolean argument true if server side, need for correct user_country_code
      query: () => ({
        url: urlVersionPrefix + `site/info${typeof window !== 'undefined' ? document.location.search : ''}`,
      }),
      transformResponse: (response: ISiteInfo) => {
        userSettingsStorage.addValues({
          currency: userSettingsStorage.getValue()?.currency || response.currency,
          langcode: response.langcode,
        })
        return response
      },
    }),
  }),
})

export const {
  endpoints: commonAppEndpoints,
  useGetConfigurationQuery,
  useGetCurrenciesQuery,
  useGetFileQuery,
  useGetLanguagesQuery,
  useGetSiteInfoQuery,
} = commonAppApi

export const { getConfiguration, getCurrencies, getFile, getLanguages, getSiteInfo } = commonAppEndpoints
